<script setup lang="ts">
const year = new Date().getFullYear()
</script>

<template>
  <NuxtLayout name="guest">
    <div>
      <slot />
    </div>
    <div class="mt-2 text-sm text-center">
      © {{ year }} Forgd. All rights reserved. <a href="https://www.forgd.com/terms-conditions" class="text-forgd-primary-300" target="_blank">Terms & Conditions</a>
      <slot name="footer" />
    </div>
  </NuxtLayout>
</template>
